<template>
  <div class="payment" v-if="payInfo">
    <div class="acea-row row-between-wrapper">
      <span class="payType" v-if="payType === '9'">微信支付</span>
      <span class="payType" v-if="payType === '10'">支付宝支付</span>
      <span class="amount">应付金额：<strong>{{amount}}</strong>元</span>
    </div>
    <div v-if="payStatus === 0">
      <div class="acea-row row-middle-wrapper">
        <div class="qrcode">
          <img :src="payInfo.qrcode_url" alt="">
          <p class="wechat-tip" v-if="payType === '9'">请使用微信扫一扫，扫描二维码支付</p>
          <p class="alipay-tip" v-if="payType === '10'">请使用支付宝扫一扫，扫描二维码支付</p>
        </div>
        <div class="tips">
          <img src="@/assets/images/wechat-tip.png" alt="" v-if="payType === '9'">
          <img src="@/assets/images/alipay-tip.png" alt="" v-if="payType === '10'">
        </div>
      </div>
    </div>
    <div v-else>
      <div class="acea-row row-middle-wrapper">
        <el-row>
          <el-col :sm="12" :lg="6">
            <el-result icon="success" title="支付成功"></el-result>
          </el-col>
          <el-col :sm="12" :lg="6">
            <div style="display: flex; justify-content: center;">
              <el-button type="primary" size="medium" @click="$router.push({name: 'orderPage'})">返回订单列表</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { orderPay, getPayStatus } from '@/api/payment'
import { ElButton, ElResult } from 'element-plus'
import 'element-plus/dist/index.css'

export default {
  name: 'paymentDetailPage',
  setup() {
    const route = useRoute()

    // 获取支付信息
    const payType = route.params.payType
    const orderSn = route.params.orderSn
    const amount = route.params.amount
    const payInfo = ref(null)
    const payStatus = ref(0)
    orderPay({ order_sn: orderSn, pay_type: payType }).then(res => {
      payInfo.value = res.data
    });

    let payStatusInterval = setInterval(() => {
      getPayStatus({ order_sn: orderSn }).then(res => {
        payStatus.value = res.data.pay_status;
        if (payStatus.value !== 0) {
          clearInterval(payStatusInterval);
        }
      });
    }, 3000);

    return {
      payType,
      payStatus,
      orderSn,
      amount,
      payInfo
    }
  },
  components: {
    [ElResult.name]: ElResult,
    [ElButton.name]: ElButton
  }
}
</script>

<style lang="less" scoped>
.payment {
  padding: 30px;
  background-color: white;
  > div:first-child {
    margin-bottom: 30px;
  }
  .payType {
    font-size: 20px;
  }
  .amount {
    color: #999;
    strong {
      font-size: 20px;
      color: red;
    }
  }
  .qrcode {
    margin: 0 70px;
    img {
      width: 310px;
      height: 310px;
      border: 1px solid #ddd;
      margin-bottom: 15px;
    }
    p {
      padding: 10px;
      color: white;
      text-align: center;
      &.wechat-tip {
        background-color: #EB5E4A;
      }
      &.alipay-tip {
        background-color: #1676FF;
      }
    }
  }
  .tips {
    width: 262px;
    height: 399px;
    margin: 0 70px;
  }
}
</style>
