import request from '@/utils/request'

// 订单支付
export const orderPay = data => {
  return request({
    url: '/goods_order/pay',
    method: 'post',
    data
  })
}

// 查询订单支付状态
export const getPayStatus = data => {
  return request({
    url: '/goods_order/pay_status',
    method: 'post',
    data
  })
}
